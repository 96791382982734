import React, { Component } from "react"
import FaqMenuItem from "./faqMenuItem"

class FaqCategories extends Component {

  organizeFaqCategories = function(faqCategories) {
    let leaves = faqCategories.filter(category => faqCategories.filter(child => child.parentId === category.id).length === 0);
    if (leaves.filter(category => category.parentId).length > 0) {
      faqCategories = faqCategories.filter(category => !category.parentId || leaves.indexOf(category) < 0);
      faqCategories.forEach(faqCategory => faqCategory.children = leaves.filter(child => child.parentId === faqCategory.id));

      return this.organizeFaqCategories(faqCategories);
    } else {
      return faqCategories;
    }
  }

  render() {
    var {categories} = this.props;
    categories = categories ? categories.filter(faqCategory => !faqCategory.deletable) : [];
    categories.forEach(faqCategory => faqCategory.selected = false);
    var organizedCategories = this.organizeFaqCategories(categories.filter(category => category !== null));

    return (
      <div className="faq-menu">
        { 
          organizedCategories.map(category => <FaqMenuItem key={category.id} category={category} level={1} />)
        }
      </div>
    )
  }
} 

export default FaqCategories
