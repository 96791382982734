import React from "react";

const Footer = () => (
    <div className="footer-territeo col-auto">
        <footer>
            <div id="help-info" className="ng-scope">
            <div id="phone-icon"><i className="fa fa-phone" aria-hidden="true"></i></div>
            <div id="phone-info">
                <p>Vous ne trouvez pas de réponse à votre question,</p>
                <p>contactez le support TERRITEO</p>
                <p>09.72.56.82.02 - <a href="mailto:support@territeo.com">support@territeo.com</a></p>
            </div>
            </div>
            <div className="rgpd-style">
                <span>
                    Les informations recueillies lors de votre contact avec le support TERRITEO sont traitées par
                    l'Administrateur du GIE TERRITEO pour répondre aux questions que vous lui adressez et fournir les
                    informations éventuellement demandées. Pour en savoir plus sur la gestion de vos données
                    personnelles et pour exercer vos droits, vous pouvez cliquer sur le lien&nbsp;<a href="https://www.territeo.com/ui/assets/images/TERRITEO-RGPD.pdf" download="TERRITEO - RGPD.pdf">suivant</a>.
                </span>
            </div>
        </footer>  
    </div>
)

export default Footer
