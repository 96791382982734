/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import Header from "./header"
import Footer from "./footer"
import FaqCategories from "./faqCategories"

import "../css/bootstrap.css"
import "../css/main.css"
import "../css/normalize.css"
import "../css/slick.css"
import "../css/fontawesome-5.8.2-all.css"
import "../css/faq.css"

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSmallWidth: false
    }
  }

  componentDidMount() {
    // Below this is where I'd like to use the media query to set the sidenavOPen to false. Just not sure how to achieve that
    this.checkWidth = () => {
      const match = window.matchMedia(`(max-width: 576px)`);
      this.setState({isSmallWidth: match.matches})
    };

    this.checkWidth();
    window.addEventListener("resize", this.checkWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWidth);
  }
  render() {

    const {children, categories, territeoUrl} = this.props;
    return (
      <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
          <>
          <Helmet title={data.site.siteMetadata.title}/>
            
            <Header siteTitle={data.site.siteMetadata.title} url={territeoUrl} />
            <div className="global_container">
              <FaqCategories categories={categories} />
              <div className="center-container">
                <main>{children}</main>
                <Footer />
              </div>
            </div>
          </>
        )}
      />
    )
  }
}

Layout.propTypes = { 
  children: PropTypes.node.isRequired 
}

export default Layout

/*faqCategory {
  id
  title
  parentId
  parentTitle
  deletable
  path
}*/
