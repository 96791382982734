import React, { Component } from "react"

class FaqMenuItem extends Component {
  url;

  constructor(props) {
    super(props);
    this.state = {
      selectedId: this.props.selectedId
    };
  }

  componentDidMount() {
    this.url = window.location.pathname.split('/');
  }

  render() {
    var {category, level} = this.props;
    if (!level) { 
      level = 1;
    }

    const selectedCategory = this.url ? this.url[this.url.length - 1] : null;

    const styleClass = "menu-item level-" + level + (category.code === selectedCategory ? " selected" : "");
    
    return (
      <>
        { category.faqs.length > 0 && <a className={styleClass} href={"/faq/" + category.code}><i className="menu-icon fa fa-angle-double-right"></i><span>{category.title}</span></a>
        }
        { category.faqs.length === 0 && <div className={styleClass + " unselectable"}><span>{category.title}</span></div> }
        { category.children && category.children.map(child => <FaqMenuItem key={child.id} category={child} level={level + 1} />) }
      </>
    )
  }
} 

export default FaqMenuItem
