import PropTypes from "prop-types"
import React from "react"
import {navigate} from "gatsby"


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      searchValue: null
    }

    this.submitSearch = this.submitSearch.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
  }

  submitSearch(event) {
    event.preventDefault();
    navigate(`/recherchePage?recherche=${this.state.searchValue}`);
  }

  handleChangeSearch(event) {
    this.setState({ searchValue: event.target.value })
  }

  render() {
    const { siteTitle } = this.props;

    return (
      <div className="header-container align-items-center">
        <div className="col"><small><span>
          <a href="/" className="header-link">{siteTitle}</a>
          </span></small></div>
        <div className="col territeo-icon" data-toggle="tooltip" data-placement="bottom" title="Ouvrir Territeo">
          <a href={this.props.url}>
              <i className="fa fa-leaf" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  clickMenu: PropTypes.func
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
